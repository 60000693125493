/* eslint-disable no-use-before-define */
import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CampaignGridItem from '../components/Campaign/CampaignGridItem'
import FilterCategory from '../components/FilterCategory'
import { getLanguage } from '../services/language'

const Filters = styled.div`

`

const Grid = styled.div`
  @media (min-width: 919px) {
    min-height: 610px;
  }
`

const GridItem = styled(CampaignGridItem)`
  margin-bottom: 25px;
`

const OnsWerk = ({ data }) => {
  const {
    wordpressPage: {
      title,
    },
    allWordpressWpCampagnes: {
      edges: campagnes,
    },
  } = data

  const language = getLanguage()

  const [activeFilters, setActiveFilters] = useState({
    services: [],
    mediums: [],
  })

  const backupCampagnes = campagnes
  const visibleCampagnes = triggerFilter()
  const filters = loadFilters()

  function loadFilters() {
    const services = backupCampagnes
      .filter(({ node: campagne }) => campagne.acf.information.dienst !== null)
      .map(({ node: campagne }) => campagne.acf.information.dienst.title)
    const mediums = backupCampagnes
      .filter(({ node: campagne }) => campagne.acf.information.medium !== null)
      .map(({ node: campagne }) => campagne.acf.information.medium.title)

    return {
      // new Set() returns unique values
      services: [...new Set(services)],
      mediums: [...new Set(mediums)],
    }
  }

  function triggerFilter() {
    return backupCampagnes.filter(filterCampagne)
  }

  function filterCampagne({ node: campagne }) {
    let serviceMatches = false
    let mediumMatches = false

    if (activeFilters.services.length > 0) {
      if (activeFilters.services.includes(campagne.acf.information.dienst.title)) {
        serviceMatches = true
      }
    } else {
      serviceMatches = true
    }

    if (activeFilters.mediums.length > 0) {
      if (activeFilters.mediums.includes(campagne.acf.information.medium.title)) {
        mediumMatches = true
      }
    } else {
      mediumMatches = true
    }

    return (
      serviceMatches && mediumMatches
    )
  }

  function setActiveFilter(name, value) {
    setActiveFilters(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <Layout>
      <SEO title={title} />
      <section className="container">
        <div className="row justify-content-between my-5">
          <Filters className="col-md-3">
            <div className="p-3">
              <h2 className="font-weight-xl mb-3">Filters</h2>
              <FilterCategory
                taxonomy={filters.services}
                activeFilterType={activeFilters.services}
                onChange={(values) => setActiveFilter('services', values)}
                title={language === 'nl_NL' ? 'Diensten' : 'Services'}
                plural={language === 'nl_NL' ? 'diensten' : 'services'}
                tag="services"
                max={5}
              />

              <FilterCategory
                taxonomy={filters.mediums}
                activeFilterType={activeFilters.mediums}
                onChange={(values) => setActiveFilter('mediums', values)}
                title={language === 'nl_NL' ? 'Medium' : 'Medium'}
                plural={language === 'nl_NL' ? 'Mediums' : 'Mediums'}
                tag="medium"
                max={5}
              />
            </div>
          </Filters>
          <Grid className="col-md-8">
            <div className="row">
              {visibleCampagnes.map(({ node: campagne }) => (
                <GridItem key={campagne.id} className="col-lg-6" campaign={campagne} />
              ))}
            </div>
          </Grid>
        </div>
      </section>
    </Layout>
  )
}

export default OnsWerk

export const pageQuery = graphql`
	query OnsWerkQuery($wordpress_id: Int!, $language: String) {
		wordpressPage(
			wordpress_id: { eq: $wordpress_id }
		) {
			id
			wordpress_id
			title
			slug
		}

		allWordpressWpCampagnes (
			filter: {
				wpml_current_locale: { eq: $language }
			}
		) {
			edges {
				node {
					id
					wordpress_id
					path
          title
					acf {
						information {
							sub_title
							branche
							medium {
								title
							}
							dienst {
								title
								acf {
									icon {					
                    localFile {
                      publicURL
                    }
									}
								}
							}
							brand {
								image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
								}
								name
							}
							image {
								localFile {
									childImageSharp {
										fluid(quality: 100, maxWidth: 1920) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
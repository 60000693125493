import React, { useState } from 'react'
import styled from 'styled-components'

import { getLanguage } from '../services/language'

const Taxonomy = styled.div`
  cursor: pointer;
  
  input {
    display: none;
  }
  
  input:hover ~ label::before {
    border: 2px solid #2b2652;
  }
  
  
  input:checked ~ label::before {
    border: 2px solid #2b2652;
    background: #2b2652;
  }
`

const Label = styled.label`
  cursor: pointer;
  user-select: none;

  &::before {
    width: 14px;
    height: 14px;
    content: "";
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns=…28 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    z-index: 2;
    cursor: pointer;
    margin-right: 10px;
    -webkit-transition: 50ms;
    transition: 50ms;
  }
`

const FilterCategory = ({
                          taxonomy,
                          max,
                          title,
                          tag,
                          activeFilterType,
                          onChange,
                        }) => {
  const [showMore, setShowMore] = useState(true)

  /**
   * to prevent duplicates
   */
  function handleChange(item) {
    if (activeFilterType.includes(item)) {
      onChange(activeFilterType.filter(i => i !== item))
    } else {
      onChange([...activeFilterType, item])
    }
  }

  if (taxonomy.length === 0) {
    return false
  }

  const language = getLanguage()

  return (
    <div className="filter-wrapper mb-4 col-12 px-0">
      <div className="filter-label font-size-m with-dot font-weight-xl mb-2">
        {title}
      </div>

      {showMore || taxonomy.length <= 5 ? (
        taxonomy.map(item => (
          <Taxonomy key={item} className="filter-field mb-1 d-flex align-items-center">
            <input
              type="checkbox"
              className="mr-3"
              value={activeFilterType.includes(item)}
              id={`${tag}_${item}`}
              onChange={() => handleChange(item)}
            />
            <Label
              className="d-flex align-items-center font-size-m font-weight-m color-text-main mb-0"
              htmlFor={`${tag}_${item}`}
            >
              <div dangerouslySetInnerHTML={{ __html: item }} />
            </Label>
          </Taxonomy>
        ))
      ) : (
        <>
          {taxonomy.slice(0, max).map(item => (
            <div key={item} className="filter-field mb-2">
              <input
                type="checkbox"
                value={activeFilterType.includes(item)}
                id={`${tag}_${item}`}
                onChange={() => handleChange(item)}
              />
              <Label
                className="d-flex align-items-center font-size-sm color-text-main mb-0"
                htmlFor={`${tag}_${item}`}
              >
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </Label>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default FilterCategory
